<template>
  <!--Content start-->
    <main id="content">
      <div class="container">
        <div class="row">
          <!-- top section -->
          <div class="col-12 mt-05">
            <div class="row py-2">
              <!--Breaking box-->
              <div class="col-2 col-sm-1 col-md-3 col-lg-2 py-1 pe-md-0 mb-md-1">
                <div class="d-inline-block d-md-block bg-primary text-white text-center breaking-caret py-1 px-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="currentColor" class="bi bi-lightning-fill" viewBox="0 0 16 16">
                    <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                  </svg>
                  <span class="d-none d-md-inline-block">أخبار عاجلة</span>
                </div>
              </div>
              <!--Breaking content-->
              <div class="col-10 col-sm-11 col-md-9 col-lg-10 ps-1 ps-md-2">
                <div class="breaking-box position-relative py-2">
                  <div class="box-carousel" data-flickity='{ "cellAlign": "left", "rightToLeft": true, "wrapAround": true, "adaptiveHeight": true, "prevNextButtons": true , "autoPlay": 5000, "pageDots": false, "imagesLoaded": true }'>
                    <!--list post-->
                    <div class="col-12 aribudin" v-for="publish in carousel.slice(0,10)" :key="publish.id">
                        <a class="h6 font-weight-light pointer" @click="getFeed(publish)">{{ publish.post_title.substring(0,70) + '...' }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Big grid slider 1 -->
            <div class="row featured-1 mb-4">
              <!--Start box news-->
              <div class="col-md-12 pb-05 pt-05 pe-md-05">
                <div class="block-title-6">
						    <h4 class="h5 border-primary">
						      <span class="bg-primary text-white "> اخبار فيديو</span>
						    </h4>
						  </div>
                 <div class="position-relative overflow-hidden">
                <!-- Carousel Card -->
                <div id="carouselVideoExample" class="carousel slide nav-slider-hover nav-dots-top-right light-dots flickity-enabled is-draggable"  data-bs-ride="carousel">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                  </div>
                  <div class="carousel-inner">
                    <div class="carousel-item is-selected" :class="{ active: index === 0 }" v-for="(publish, index) in video" :key="index">
                        <video  class="plyr" autoplay style="margin-right: 0px;width: 100%;" controls="" controlsList="nodownload" playsinline>
                          <source :src="publish.trailer" type="Video/mp4" size="720">
                        </video>
                    </div>
                  </div>
                   <button class="flickity-button flickity-prev-next-button previous" type="button" data-bs-target="#carouselVideoExample" data-bs-slide="prev">
                    <svg class="flickity-button-icon" viewBox="0 0 100 100">
                      <path  d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" transform="translate(100, 100) rotate(180) " class="arrow"></path>
                    </svg>
                  </button>
                  <button class="flickity-button flickity-prev-next-button next" type="button" data-bs-target="#carouselVideoExample" data-bs-slide="next">
                    <svg class="flickity-button-icon" viewBox="0 0 100 100">
                      <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path>
                    </svg>
                  </button>
                </div>
                <!-- End Carousel Card -->
                 </div>
                    <div class="d-grid " style="margin-top: 5%;margin-bottom: 5%;">
                      <router-link class="btn btn-primary animated-button1" to="/Infographic">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                         مزيد من الفيديو
                      </router-link>
                    </div>
              </div>
              <!-- <div class="col-md-6 pt-05 ps-md-05">
                <aside class="widget">
                <div class="block-title-6">
						      <h4 class="h5 border-primary">
						        <span class="bg-primary text-white ">كاركاتير</span>
						      </h4>
						    </div>
                 <div class="row newsbox">
                  <article class="col-6" v-for="publish in caricatures.slice(0,2)" :key="publish.id">
                    <div class="card card-full text-white overflow zoom" >
                      <div class="height-ratio image-wrapper">
                        <a class="card-link pointer" >
                          <img class="d-block w-100  myImg" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,45)">
                        </a>
                        <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-shadow">
                          <a class="card-link pointer" >
                            <h3 class="h6 h4-sm h6-md h5-lg text-white my-1 ">{{ publish.title_ar.substring(0,40) }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h2 class="card-title h1-sm h3-md ">
                        <a class="card-link pointer">{{ publish.title_ar.substring(0,70) }}</a>
                      </h2>
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 ">
                          <a class="font-weight-bold" href="">تأق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                    </div>
                  </article>
                </div>
               
                <div class="d-grid " style="margin-top: 2%;margin-bottom: 2%;">
                      <router-link class="btn btn-primary animated-button1" to="/Caricature">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                         مزيد من الكاركاتير
                      </router-link>
                    </div>
                <div class="gap-0"></div>
              </aside>
              </div> -->
              <!--End box news-->
            </div>
            <!--End Big grid slider 1-->
          </div>
          <!-- start full column -->
          <div class="col-12 mb-4">
            <div class="row featured-1 mb-5">
              <div class="col-md-6 pb-05 pt-05 pe-md-05">
                <div class="block-title-6">
						    <h4 class="h5 border-primary">
						      <span class="bg-secondary text-white ">اخر الاخبار</span>
						    </h4>
						    </div>
                <div class="position-relative overflow-hidden">
                  <div class="nav-slider-hover nav-dots-top-right light-dots" data-flickity='{ "cellAlign": "right", "rightToLeft": true, "wrapAround": true, "adaptiveHeight": true, "prevNextButtons": true , "pageDots": true, "imagesLoaded": true }'>
                    <!--Item slider-->
                    <article class="col-12" :class="{ active: index === 0 }" v-for="(publish, index) in carousel.slice(0,5)" :key="index">
                      <div class="card card-full text-light overflow zoom">
                        <!--thumbnail-->
                        <div class="height-ratio image-wrapper pointer">
                          <a @click="getFeed(publish)">
                            <img class="img-fluid img-harry" v-lazy="publish.open_graph_image" alt="وصف الصورة">
                          </a>
                          <div class="position-absolute p-3 b-0 w-100 bg-lg-shadow content-container">
                            <!--title-->
                            <a @click="getFeed(publish)">
                              <h3 class="h1-sm h2-md display-4-lg font-weight-500 text-white text-harry">{{ publish.post_title }}</h3>
                            </a>
                            <!-- meta title -->
                            <!-- <div class="news-meta">
                              <span class="news-author">تاق برس </span>
                              <time class="news-date" datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </article>
                    <!--end item slider-->
                  </div>
                </div>
              </div>
              <div class="col-md-6 pt-05 ps-md-05">
                <!-- Block title -->
              <!-- <div class="block-title-6">
						    <h4 class="h5 border-primary">
						      <span class="bg-primary text-white ">مزيد من اخر الاخبار</span>
						    </h4>
						  </div> -->
              <div class="block-title-4">
                  <h4 class="h5 title-arrow ">
                    <span>مزيد من اخر الاخبار</span>
                  </h4>
                </div>
              <!-- block content -->
                 <div class="row newsbox">
                  <!--news box-->
                  <article class="col-6" v-for="publish in carousel.slice(5,9)" :key="publish.id">
                    <div class="card card-full text-white overflow zoom" >
                      <div class="height-ratio image-wrapper">
                        <!--thumbnail-->
                        <a class="card-link pointer"  @click="getFeed(publish)">
                          <img class="d-block w-100 img-harry1" v-lazy="publish.open_graph_image" alt="">
                        </a>
                        <!-- category & title -->
                        <a class="p-1 badge bg-primary text-white top-right" >{{ publish.name }}</a>
                        <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 text-harry2">
                          <a class="card-link pointer"  @click="getFeed(publish)">
                            <h3 class="h6 h4-sm h6-md h5-lg text-white my-1 text-harry">{{ publish.post_title.substring(0,80) }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <!-- End block -->
          </div>
          <!-- end full column -->




          <!--start left column-->
          <div class="col-md-8">
            <!-- Block start -->
						<div class="block-area">
						  <!--block title-->
						  <div class="block-title-6">
						    <h4 class="h5 border-primary">
						      <span class="bg-secondary text-white ">السياسية</span>
						    </h4>
						  </div>
						  <!--block content-->
						  <div class="row">
						    <!--post left start-->
						    <div class="col-lg-6">
						      <article class="card card-full hover-a mb-4" v-for="publish in policy.slice(0,2)" :key="publish.id">
						        <!--thumbnail-->
						        <div class="ratio_360-202 image-wrapper">
						          <a class="card-link pointer"  @click="getFeed(publish)">
						            <img class="d-block w-100 " v-lazy="publish.open_graph_image" alt="">
						          </a>
						        </div>
						        <div class="card-body">
						          <!-- title -->
						          <h2 class="card-title h1-sm h3-lg">
						            <a class="card-link pointer "  @click="getFeed(publish)">{{ publish.post_title.substring(0,70)+".." }}</a>
						          </h2>
						          <!-- author, date and comments -->
						          <div class="card-text mb-2 text-muted small">
						            <span class="d-none d-sm-inline me-1">
						              <a class="font-weight-bold " href="#">تاق برس</a>
						            </span>
						            <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
						            <!-- <span title="9 تعليق" class="float-end">
						              <span class="icon-comments"></span> 9
						            </span> -->
						          </div>
						          <!--description-->
						          <!-- <p class="card-text">هذه بطاقة أوسع مع نص داعم أدناه كمقدمة طبيعية لمحتوى إضافي. هذا المحتوى أطول قليلاً.</p> -->
						        </div>
						      </article>
						    </div>
						    <!--end post left-->
						    <!--post right list start-->
						    <div class="col-lg-6">
						      <!--post list-->
						      <article class="card card-full hover-a mb-4" v-for="publish in policy.slice(2,8)" :key="publish.id">
						        <div class="row">
						          <!--thumbnail-->
						          <div class="col-3 col-md-4 pe-2 pe-md-0">
						            <div class="ratio_115-80 image-wrapper">
						              <a class="card-link pointer"  @click="getFeed(publish)">
						                <img class="d-block w-100 " v-lazy="publish.open_graph_image" alt="">
						              </a>
						            </div>
						          </div>
						          <!-- title & date -->
						          <div class="col-9 col-md-8">
						            <div class="card-body pt-0">
						              <h3 id="count" class="card-title h6 h5-sm h6-lg ">
						                <a class="card-link pointer "  @click="getFeed(publish)">{{ publish.post_title.substring(0,60)+".." }}</a>
						              </h3>
						              <div class="card-text small text-muted">
						                <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
						              </div>
						            </div>
						          </div>
						        </div>
						      </article>
						      <!--End post list-->
						    </div>
						    <!--end post right list-->
						  </div>
						  <!-- end block content -->
						</div>
						<!--End Block-->

            <!--Block start-->
            <div class="block-area">
              <div class="row">
                <!--left post-->
                <div class="col-lg-6">
                  <!--block title-->
                  <div class="block-title-6">
                    <h4 class="h5 border-primary">
                      <span class="bg-secondary text-white ">حوادث وقضايا</span>
                    </h4>
                  </div>
                  <!--post big start-->
                  <div class="big-post">
                    <article class="card card-full hover-a mb-4" v-for="publish in accidents.slice(0,1)" :key="publish.id">
                      <!--thumbnail-->
                      <div class="ratio_360-202 image-wrapper">
                        <a class="card-link pointer"  @click="getFeed(publish)">
                          <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                        </a>
                      </div>
                      <div class="card-body">
                        <!--title-->
                        <h2 class="card-title h1-sm h3-lg ">
                          <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70)+".." }}</a>
                        </h2>
                        <!-- author, date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <span class="d-none d-sm-inline me-1 ">
                            <a class="font-weight-bold" href="#">تاق برس</a>
                          </span>
                          <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                          <!-- <span title="9 تعليق" class="float-end">
                            <span class="icon-comments"></span> 9
                          </span> -->
                        </div>
                        <!--description-->
                        <!-- <p class="card-text">هذه بطاقة أوسع مع نص داعم أدناه كمقدمة طبيعية لمحتوى إضافي. هذا المحتوى أطول قليلاً.</p> -->
                      </div>
                    </article>
                  </div>
                  <!--end post big-->
                  <!--post small start-->
                  <div class="small-post">
                    <!--post list-->
                    <article class="card card-full hover-a mb-4" v-for="publish in accidents.slice(1,4)" :key="publish.id">
                      <div class="row">
                        <!--thumbnail-->
                        <div class="col-3 col-md-4 pe-2 pe-md-0">
                          <div class="ratio_115-80 image-wrapper">
                            <a class="card-link pointer"  @click="getFeed(publish)">
                              <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                            </a>
                          </div>
                        </div>
                        <!-- title & date -->
                        <div class="col-9 col-md-8">
                          <div class="card-body pt-0">
                            <h3 class="card-title h6 h5-sm h6-lg ">
                              <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,60)+".." }}</a>
                            </h3>
                            <div class="card-text small text-muted">
                              <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <!--post list-->                   
                  </div>
                  <!--end post small-->
                </div>
                <!--end left post-->

                <!--right post-->
                <div class="col-lg-6">
                  <!--block title-->
                  <div class="block-title-6">
                    <h4 class="h5 border-primary">
                      <span class="bg-secondary text-white ">اقتصاد وأعمال</span>
                    </h4>
                  </div>
                  <!--post big start-->
                  <div class="big-post">
                    <article class="card card-full hover-a mb-4" v-for="publish in economie.slice(0,1)" :key="publish.id">
                      <!--thumbnail-->
                      <div class="ratio_360-202 image-wrapper">
                        <a class="card-link pointer"  @click="getFeed(publish)">
                          <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                        </a>
                      </div>
                      <div class="card-body">
                        <!--title-->
                        <h2 class="card-title h1-sm h3-lg ">
                          <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70)+".." }}</a>
                        </h2>
                        <!-- author, date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <span class="d-none d-sm-inline me-1 ">
                            <a class="font-weight-bold" href="#">تاق برس</a>
                          </span>
                          <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                          <!-- <span title="9 تعليق" class="float-end">
                            <span class="icon-comments"></span> 9
                          </span> -->
                        </div>
                        <!--description-->
                      </div>
                    </article>
                  </div>
                  <!--end post big-->
                  <!--post small start-->
                  <div class="small-post">
                    <!--post list-->
                    <article class="card card-full hover-a mb-4" v-for="publish in economie.slice(1,4)" :key="publish.id">
                      <div class="row">
                        <!--thumbnail-->
                        <div class="col-3 col-md-4 pe-2 pe-md-0">
                          <div class="ratio_115-80 image-wrapper">
                            <a class="card-link pointer"  @click="getFeed(publish)">
                              <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                            </a>
                          </div>
                        </div>
                        <!-- title & date -->
                        <div class="col-9 col-md-8">
                          <div class="card-body pt-0">
                            <h3 class="card-title h6 h5-sm h6-lg ">
                              <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,60)+".." }}</a>
                            </h3>
                            <div class="card-text small text-muted">
                              <time datetime="2019-10-09">{{ publish.post_date.substring(0,10) }}</time>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <!--post list-->
                  </div>
                  <!--end post small-->
                </div>
                <!--end right post-->
              </div>
            </div>
            <!--End Block-->

            <!--Block start-->
						<div class="block-area">
						  <!-- block title -->
						  <div class="block-title-6">
						    <h4 class="h5 border-primary">
						      <span class="bg-secondary text-white ">رياضة</span>
						    </h4>
						  </div>
						  <!--big post start-->
						  <div class="big-post">
						    <article class="card card-full hover-a mb-4" v-for="publish in sport.slice(0,1)" :key="publish.id">
						      <div class="row">
						        <div class="col-lg-6">
						          <!-- thumbnail -->
						          <div class="ratio_360-202 image-wrapper">
						            <a class="card-link pointer"  @click="getFeed(publish)">
						              <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
						            </a>
						          </div>
						        </div>
						        <div class="col-lg-6">
						          <div class="card-body pt-3 pt-lg-0">
						            <!--title-->
						            <h2 class="card-title h1-sm h3-lg ">
						              <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,150)+".." }}</a>
						            </h2>
						            <!-- author, date and comments -->
						            <div class="card-text mb-2 text-muted small">
						              <span class="d-none d-sm-inline me-1 ">
						                <a class="font-weight-bold" href="">تأق برس </a>
						              </span>
						              <time datetime="2019-10-21">{{ publish.post_date.substring(0,10) }}</time>
						              <!-- <span title="5 تعليق" class="float-end">
						                <span class="icon-comments"></span> 5
						              </span> -->
						            </div>
						            <!--description-->
						            <!-- <p class="card-text">هذه بطاقة أوسع مع نص داعم أدناه كمقدمة طبيعية لمحتوى إضافي ...</p> -->
						          </div>
						        </div>
						      </div>
						    </article>
						  </div>
						  <!--small post start-->
						  <div class="row small-post">
						    <!-- post list -->
						    <article class="col-lg-6" v-for="publish in sport.slice(1,5)" :key="publish.id">
						      <div class="card card-full hover-a mb-4">
						        <div class="row">
						          <!--thumbnail-->
						          <div class="col-3 col-md-4 pe-2 pe-md-0">
						            <div class="ratio_115-80 image-wrapper">
						              <a class="card-link pointer" @click="getFeed(publish)">
						                <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
						              </a>
						            </div>
						          </div>
						          <!-- title & date -->
						          <div class="col-9 col-md-8">
						            <div class="card-body pt-0">
						              <h3 class="card-title h6 h5-sm h6-lg ">
						                <a class="card-link pointer" @click="getFeed(publish)">{{ publish.post_title.substring(0,70)+".." }}</a>
						              </h3>
						              <div class="card-text small text-muted">
						                <time datetime="2019-10-19">{{ publish.post_date.substring(0,10) }}</time>
						              </div>
						            </div>
						          </div>
						        </div>
						      </div>
						    </article>
						    <!-- post list -->
						    <!-- End post list -->
						  </div>
						</div>
						<!-- End block -->

            <!--Block start-->
            <div class="block-area">
              <!--block title-->
              <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <span class="bg-secondary text-white ">منوعات</span>
                </h4>
              </div>
              <!-- block content -->
              <div class="row">
                <!--post list-->
                <article class="col-sm-6 col-md-12 col-lg-6" v-for="publish in mix" :key="publish.id">
                  <!--thumbnail-->
                      <div class="ratio_360-202 image-wrapper">
                        <a class="card-link pointer"  @click="getFeed(publish)">
                          <img class="img-fluid " v-lazy="publish.open_graph_image"  alt="">
                        </a>
                      </div>
                      <div class="card-body">
                        <!--title-->
                        <h2 class="card-title h1-sm h3-lg ">
                          <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70)+".." }}</a>
                        </h2>
                        <!-- author, date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <span class="d-none d-sm-inline me-1 ">
                            <a class="font-weight-bold" href="#">تاق برس</a>
                          </span>
                          <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                          <!-- <span title="9 تعليق" class="float-end">
                            <span class="icon-comments"></span> 9
                          </span> -->
                        </div>
                        <!--description-->
                        <!-- <p class="card-text">هذه بطاقة أوسع مع نص داعم أدناه كمقدمة طبيعية لمحتوى إضافي. هذا المحتوى أطول قليلاً.</p> -->
                      </div>
                </article>
                <!--post list-->              
                <div class="gap-05"></div>
              </div>
              <!-- end block content -->
            </div>
            <!--End Block-->
          </div>
          <!--end left column-->
          <!--start right column-->
          <aside class="col-md-4 end-sidebar-lg">
            <div class="sticky">
             <!--widget start-->
              <aside class="widget">
                <!-- block title -->
                <div class="block-title-4">
                  <h4 class="h5 title-arrow">
                    <span>مقالات</span>
                  </h4>
                </div>
                <!-- block content -->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in articles" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a class="card-link pointer"  @click="getFeed(publish)">
                            <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md ">
                            <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>                 
                  <!--End post list-->
                </div>
                <!--end block content-->
                <div class="gap-0"></div>
              </aside>
              <!--End widget-->
              <!-- post with number -->
              <aside class="widget">
                <!--block title-->
                <div class="block-title-4">
                  <h4 class="h5 title-arrow ">
                    <span>اخر الأخبار</span>
                  </h4>
                </div>
                <!-- Block content -->
                <ul class="post-number list-unstyled border-bottom-last-0 rounded mb-3">
                  <li class="hover-a" v-for="publish in carousel.slice(9,14)" :key="publish.id">
                    <a class="h5 h6-md h5-lg pointer " @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                  </li>
                  
                </ul>
                <!-- end block content -->
                <div class="gap-0"></div>
              </aside>
              <!-- end post with number -->
              <!-- latest post -->
              <aside class="widget">
                <!--Block title-->
                <div class="block-title-4">
                  <h4 class="h5 title-arrow ">
                    <span>صحة</span>
                  </h4>
                </div>
                <!--post big start-->
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in health.slice(0,1)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a class="card-link pointer"  @click="getFeed(publish)">
                        <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                      </a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h2 class="card-title h1-sm h3-md ">
                        <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                      </h2>
                      <!-- author & date -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 ">
                          <a class="font-weight-bold" href="">تأق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                </div>
                <!--end post big-->
                <!--post small start-->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in health.slice(1,4)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a class="card-link pointer"  @click="getFeed(publish)">
                            <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md ">
                            <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                 
                  <!--End post list-->
                </div>
                <!--end post small-->
                <div class="gap-0"></div>
              </aside>
              <!-- end latest post -->

              <!-- latest post -->
              <aside class="widget">
                <!--Block title-->
                <div class="block-title-4">
                  <h4 class="h5 title-arrow ">
                    <span>علوم وتكنلوجيا</span>
                  </h4>
                </div>
                <!--post big start-->
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in technology.slice(0,1)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a class="card-link pointer"  @click="getFeed(publish)">
                        <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                      </a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h2 class="card-title h1-sm h3-md ">
                        <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                      </h2>
                      <!-- author & date -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 ">
                          <a class="font-weight-bold" href="">تأق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                </div>
                <!--end post big-->
                <!--post small start-->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in technology.slice(1,4)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a class="card-link pointer"  @click="getFeed(publish)">
                            <img class="img-fluid " v-lazy="publish.open_graph_image" alt="">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md ">
                            <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                 
                  <!--End post list-->
                </div>
                <!--end post small-->
                <div class="gap-0"></div>
              </aside>
              <!-- end latest post -->
            </div>
          </aside>
          <!--end right column-->

        </div>
      </div>
  
    </main>
    <!--End Content-->
    <!-- The Modal -->
        <div id="myModal" class="modal">
          <!-- The Close Button -->
          <span class="close" onclick="document.getElementById('myModal').style.display='none'">&times;</span>
          <!-- Modal Content (The Image) -->
            <img class="modal-content" id="img01">
            <!-- Modal Caption (Image Text) -->
            <div id="caption"></div>
        </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import $ from 'jquery'
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next';
import { useToast } from "vue-toastification";
import { watchEffect } from '@vue/runtime-core';
import { useMeta } from 'vue-meta'
export default {
  name: 'HomePage',
  mounted() {
      const s = document.createElement('script');
        s.type = 'text/javascript';
        s. src = '/assets/vendor/flickity/flickity.pkgd.min.js';
        document.body.appendChild(s);


      var modal = document.getElementById("myModal");
      var i;
      var img = document.getElementsByClassName("myImg");
      var modalImg = document.getElementById("img01");
      for(i=0;i< img.length;i++) {    
        img[i].onclick = function(){
          modal.style.display = "block";
          modalImg.src = this.src;
        }
      }
      var span = document.getElementsByClassName("close")[0];
      span.onclick = function() { 
        modal.style.display = "none";
      }

        // Start play Video when Slides active Javascript
        let allVids = $("#carouselVideoExample").find('.carousel-item');
          allVids.each(function(index, el) { 
            if (index !== 0) {
              $(this).find('video')[0].pause();
            }
          }); 
        $("#carouselVideoExample").on('slide.bs.carousel', function(ev) {
          let slides = $(this).find('.carousel-item');
          let pvid = slides[ev.from].querySelectorAll('video')[0];
          let vid = slides[ev.to].querySelectorAll('video')[0];
          let isPlaying = vid.currentTime > 0 && vid.readyState > 2;
          vid.play();
          if (isPlaying) {
            pvid.pause();
          }
        });
      // end play Video when Slides active Javascript
      
      // Video stop automatice whene Slides Next Javascript
      $('#carouselVideoExample').carousel({
        interval: 0
      });
      $('.flickity-prev-next-button.previous').click(function() {
         $('#carouselVideoExample').carousel('prev');
         $("video").each(function () { this.pause() });
      });
      $('.flickity-prev-next-button.next').click(function() {
         $('#carouselVideoExample').carousel('next');
         $("video").each(function () { this.pause() });
      });
      $('button.close').click(function(){
        $("video").each(function () { this.pause() });
       }); 
      // end Video stop automatice whene Slides Next Javascript

      var videos = document.getElementsByTagName("video"),
        fraction = 0.5;
      function checkScroll() {
      for(var i = 0; i < videos.length; i++) {
        var video = videos[i];
        var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
            b = y + h, //bottom
            visibleX, visibleY, visible;
            visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
            visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));
            visible = visibleX * visibleY / (w * h);
            if (visible > fraction) {
                video.play();
            } else {
                video.pause();
            }
      }
      }
      window.addEventListener('scroll', checkScroll, false);
      window.addEventListener('resize', checkScroll, false);
      var $carouselExampleControls = $("#carouselExampleControls");
      $carouselExampleControls.carousel({
         interval: 3000
      });
  },

  async setup() {
        const video = ref([]);
        const carousel = ref([]);
        const policy = ref([]);
        const accidents = ref([]);
        const economie = ref([]);
        const sport = ref([]);
        const mix = ref([]);
        const articles = ref([]);
        const health = ref([]);
        const technology = ref([]);
        const caricatures = ref([]);

        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const toast = useToast();
        useMeta({ title: 'اخبار السودان والعالم' }),
        watchEffect(() => {
            if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
            let urlParams = new URLSearchParams(window.location.search);
            // console.log(urlParams.get('msisdn'))
            if(urlParams.has('msisdn')) {
                HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                        cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                        toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    }
                    if(cookie.getCookie("article_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "ReadArticle", params: { id: cookie.getCookie("article_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
        }
        });
        
        try {
           await HTTP.get("getVideos.php?LIMIT=5&OFFSET=0").then((res) => {
              video.value = res.data.getVideos;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getPost.php?getPost&LIMIT=15&OFFSET=0").then((res) => {
              carousel.value = res.data.getPost;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=2&LIMIT=8&OFFSET=0").then((res) => {
              policy.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }  
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=1189&LIMIT=5&OFFSET=0").then((res) => {
              accidents.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }  
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=4&LIMIT=5&OFFSET=0").then((res) => {
              economie.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }  
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=5&LIMIT=6&OFFSET=0").then((res) => {
              sport.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=4&OFFSET=0").then((res) => {
              mix.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=8&LIMIT=5&OFFSET=0").then((res) => {
              articles.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=6342&LIMIT=4&OFFSET=0").then((res) => {
              health.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=6343&LIMIT=4&OFFSET=0").then((res) => {
              technology.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }
        try {
           await HTTP.get("getCaricature.php?LIMIT=4&OFFSET=0").then((res) => {
              caricatures.value = res.data.Caricature;
           })
        } catch (err) {
           console.log(err);
        }
        const getFeed = (publish) => {
          cookie.setCookie('article_id', publish.id)
          router.push({ name: "ReadArticle", params: { id: publish.id } });
        };
        const getVideo = (publish) => {
          router.push({ name: "Video", params: { id: publish.id } });
        };
        return {
           carousel,
           video,
           policy,
           accidents,
           economie,
           sport,
           mix,
           articles,
           health,
           technology,
           caricatures,
           getFeed,
           getVideo
        };
   },
}
</script>

<style scoped>
 /* Style the Image Used to Trigger the Modal */
.myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
}

.pointer {
  cursor: pointer;
}


/* html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  min-height: 100%;
  position: relative;
  padding-bottom: 3rem;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  background: linear-gradient(170deg, rgba(49, 57, 73, 0.8) 20%, rgba(49, 57, 73, 0.5) 20%, rgba(49, 57, 73, 0.5) 35%, rgba(41, 48, 61, 0.6) 35%, rgba(41, 48, 61, 0.8) 45%, rgba(31, 36, 46, 0.5) 45%, rgba(31, 36, 46, 0.8) 75%, rgba(49, 57, 73, 0.5) 75%), linear-gradient(45deg, rgba(20, 24, 31, 0.8) 0%, rgba(41, 48, 61, 0.8) 50%, rgba(82, 95, 122, 0.8) 50%, rgba(133, 146, 173, 0.8) 100%) #313949;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
} */

/*
 * Animated CSS button
 * Copyright Alexander Bodin 2019-09-07
 *
 * Useage: .class {@import button($button-size, $hue, $sat);}
 */
.animated-button {
  background: linear-gradient(-30deg, #0b1b3d 50%, #08142b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e0f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button1 {
  background-image: linear-gradient(0deg, #00878C 0, #0FABA0);
  /* background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%); */
  padding: 10px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4d4;
  font-size: 15px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button2 {
  background: linear-gradient(-30deg, #3d240b 50%, #2b1a08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7e6d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button2::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad9985;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button2:hover::before {
  opacity: 0.2;
}

.animated-button2 span {
  position: absolute;
}

.animated-button2 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to left, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button2 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to top, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button2 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to right, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button2 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to bottom, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button3 {
  background: linear-gradient(-30deg, #3d3d0b 50%, #2b2b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button3::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #adad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button3:hover::before {
  opacity: 0.2;
}

.animated-button3 span {
  position: absolute;
}

.animated-button3 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to left, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button3 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to top, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button3 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to right, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button3 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to bottom, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button4 {
  background: linear-gradient(-30deg, #243d0b 50%, #1a2b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button4::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #99ad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button4:hover::before {
  opacity: 0.2;
}

.animated-button4 span {
  position: absolute;
}

.animated-button4 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to left, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button4 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to top, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button4 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to right, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button4 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to bottom, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button5 {
  background: linear-gradient(-30deg, #0b3d0b 50%, #082b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button5::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button5:hover::before {
  opacity: 0.2;
}

.animated-button5 span {
  position: absolute;
}

.animated-button5 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to left, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button5 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to top, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button5 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to right, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button5 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to bottom, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button6 {
  background: linear-gradient(-30deg, #0b3d24 50%, #082b1a 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7e6;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button6::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad99;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button6:hover::before {
  opacity: 0.2;
}

.animated-button6 span {
  position: absolute;
}

.animated-button6 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to left, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button6 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to top, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button6 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to right, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button6 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to bottom, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button7 {
  background: linear-gradient(-30deg, #0b3d3d 50%, #082b2b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button7::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85adad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button7:hover::before {
  opacity: 0.2;
}

.animated-button7 span {
  position: absolute;
}

.animated-button7 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to left, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button7 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to top, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button7 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to right, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button7 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to bottom, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button8 {
  background: linear-gradient(-30deg, #0b243d 50%, #081a2b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e6f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button8::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8599ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button8:hover::before {
  opacity: 0.2;
}

.animated-button8 span {
  position: absolute;
}

.animated-button8 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to left, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button8 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to top, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button8 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to right, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button8 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to bottom, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button9 {
  background: linear-gradient(-30deg, #0b0b3d 50%, #08082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button9::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8585ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button9:hover::before {
  opacity: 0.2;
}

.animated-button9 span {
  position: absolute;
}

.animated-button9 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to left, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button9 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to top, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button9 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to right, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button9 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to bottom, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button10 {
  background: linear-gradient(-30deg, #240b3d 50%, #1a082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button10::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #9985ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button10:hover::before {
  opacity: 0.2;
}

.animated-button10 span {
  position: absolute;
}

.animated-button10 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to left, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button10 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to top, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button10 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to right, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button10 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to bottom, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button11 {
  background: linear-gradient(-30deg, #3d0b3d 50%, #2b082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button11::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad85ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button11:hover::before {
  opacity: 0.2;
}

.animated-button11 span {
  position: absolute;
}

.animated-button11 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to left, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button11 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to top, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button11 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to right, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button11 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to bottom, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button12 {
  background: linear-gradient(-30deg, #3d0b24 50%, #2b081a 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4e6;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button12::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8599;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button12:hover::before {
  opacity: 0.2;
}

.animated-button12 span {
  position: absolute;
}

.animated-button12 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 26, 0)), to(#d92680));
  background: linear-gradient(to left, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button12 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 26, 0)), to(#d92680));
  background: linear-gradient(to top, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button12 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 26, 0)), to(#d92680));
  background: linear-gradient(to right, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button12 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 26, 0)), to(#d92680));
  background: linear-gradient(to bottom, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
/*# sourceMappingURL=button.css.map */

</style>